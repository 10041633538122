<template>
  <div class="modal is-active">
    <component :data="config" :is="dynamicComponent" v-if="id" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import noScroll from 'no-scroll'
export default {
  name: 'Modal',
  computed: {
    ...mapState('modal', ['id', 'config']),
    ...mapState('overlay', ['mobNav']),
    dynamicComponent() {
      const [module, filename] = this.id.split('/')
      return () => import('modules/' + module + '/modal/' + filename + '.vue')
    }
  },
  mounted: () => noScroll.on(),
  destroyed() {
    if (!this.mobNav) {
      noScroll.off()
    }
  }
}
</script>

<style lang="sass" scoped>
.modal
  z-index: 96
</style>

<style lang="sass">
$modal-card-shadow: 0 .625rem 1.25rem rgba(0, 0, 0, 0.25)
.modal-card
  box-shadow: $modal-card-shadow
</style>
